import { Sync } from "@mui/icons-material";
import { mf } from "../utils/formatters";
import { DialogButton } from "./dialog-button";
import { SyncProductsDialog } from "./dialogs/sync-products-dialog";
import { PaperAlert } from "./paper-alert";

const content = {
  message: mf(
    `{count, plural, =1 {1 edited product has} other {# edited products have}} not been synced with location devices.`,
  ),
};

export function OutOfSyncAlert(props: {
  revalidateView: () => void;
  productsOutOfSync: number;
}) {
  return (
    <PaperAlert
      action={
        <DialogButton
          size="small"
          color="warning"
          renderDialog={(dialogProps) => (
            <SyncProductsDialog
              {...dialogProps}
              onSuccess={props.revalidateView}
              productsOutOfSync={props.productsOutOfSync}
            />
          )}
        >
          Sync
        </DialogButton>
      }
      color="warning"
      icon={<Sync fontSize="small" />}
    >
      {content.message.format({ count: props.productsOutOfSync })}
    </PaperAlert>
  );
}
