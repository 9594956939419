import {
  Box,
  MenuItem,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { unpackResponse, useClient } from "../../client";
import { Schema } from "../../client/types";
import { CheckboxField, getCheckboxFieldProps } from "../checkbox-field";
import { FormDialog } from "../form-dialog";
import { getNumberFieldProps, NumberField } from "../number-field";
import { getTextFieldProps } from "../text-field";

function StandardTextField(
  props: Omit<TextFieldProps, "variant" | "fullWidth">,
) {
  return (
    <TextField
      {...props}
      fullWidth
      variant="standard"
      InputLabelProps={{ shrink: true }}
    />
  );
}

type InitialValues = Required<Schema["ProductUpdate"]>;

export function AddProductDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  locationId: number;
  productTypes: Schema["ProductType"][];
}) {
  const client = useClient();
  const formik = useFormik<InitialValues>({
    initialValues: {
      id: 0,
      name: "",
      isAbvDisplayed: false,
      isActive: false,
      isAdvertised: false,
      isIbuDisplayed: false,
      isSynced: false,
      isUntappdSynced: false,
      locationId: props.locationId,
      plu: 0,
      pricePerUnit: 0,
      productTypeId: props.productTypes.at(0)?.id ?? 0,
      unitsPerServing: 0,
      producer: "",
      ibu: 0,
      isCaffeinated: false,
      abv: 0,
      externalId: 0,
      growlerPrice: 0,
      isInUse: true,
      imageFileId: null,
      style: "",
      tastingNotes: "",
      createDate: "",
      updateDate: "",
      lastUseTime: "",
      portions: [],
      happyHourPrice1: null,
      happyHourPrice2: null,
      happyHourPricePercentDiscount1: null,
      happyHourPricePercentDiscount2: null,
    },
    async onSubmit(values, formikHelpers) {
      await unpackResponse(
        client.POST("/locations/{locationId}/products", {
          params: { path: { locationId: props.locationId } },
          body: values,
        }),
      );

      props.onSuccess();
    },
  });

  return (
    <FormDialog
      {...props}
      form={formik}
      padding={10}
      title="Add product"
      submitLabel="Add"
      buttonVariant="contained"
      maxWidth="md"
    >
      <Stack gap={8}>
        <Stack gap={4} sx={{ marginBlockStart: 4 }}>
          <Typography variant="h5" component={"h2"}>
            About
          </Typography>
          <StandardTextField
            {...getTextFieldProps(formik, "name")}
            label="Product Name"
          />
          <Stack direction="row" gap={8}>
            <StandardTextField
              {...getTextFieldProps(formik, "productTypeId")}
              label="Product Type"
              select
            >
              {props.productTypes.map((productType) => {
                return (
                  <MenuItem value={productType.id}>
                    {productType.label}
                  </MenuItem>
                );
              })}
            </StandardTextField>

            <StandardTextField
              {...getTextFieldProps(formik, "style")}
              label="Style"
            />
          </Stack>

          <Stack direction="row" gap={8}>
            <StandardTextField
              {...getTextFieldProps(formik, "producer")}
              label="Producer"
            />
            <StandardTextField
              {...getTextFieldProps(formik, "unitsPerServing")}
              label="Units per Serving"
            />
          </Stack>

          <StandardTextField
            {...getTextFieldProps(formik, "tastingNotes")}
            multiline
            label="Tasting Notes"
          />
        </Stack>

        <Stack gap={4}>
          <Typography variant="h5" component={"h2"}>
            Alcohol volume, bitterness and caffeine
          </Typography>

          <Box sx={{ display: "grid", gridTemplateColumns: "2fr 1fr", gap: 4 }}>
            <StandardTextField
              {...getTextFieldProps(formik, "abv")}
              label="Alcohol by Volume (ABV)"
            />
            <CheckboxField
              {...getCheckboxFieldProps(formik, "isAbvDisplayed")}
              label="Show ABV on tap screen"
            />
          </Box>

          <Box sx={{ display: "grid", gridTemplateColumns: "2fr 1fr", gap: 4 }}>
            <StandardTextField
              {...getTextFieldProps(formik, "ibu")}
              label="International Bitterness Units (IBU)"
            />
            <CheckboxField
              {...getCheckboxFieldProps(formik, "isIbuDisplayed")}
              label="Show IBU on tap screen"
            />
          </Box>

          <CheckboxField
            {...getCheckboxFieldProps(formik, "isCaffeinated")}
            label="Is the product caffeinated?"
          />
        </Stack>

        <Stack gap={4}>
          <Typography variant="h5" component={"h2"}>
            Basic prices
          </Typography>

          <Stack direction="row" gap={8}>
            <NumberField
              {...getNumberFieldProps(formik, "pricePerUnit")}
              label="Base volume unit price"
              adornment="$"
              fullWidth
            />

            <NumberField
              {...getNumberFieldProps(formik, "growlerPrice")}
              label="Price for growler"
              adornment="$"
              fullWidth
            />
          </Stack>
        </Stack>
      </Stack>
    </FormDialog>
  );
}

export function EditProductDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const formik = useFormik({
    initialValues: {},
    onSubmit(values, formikHelpers) {},
  });

  return (
    <FormDialog
      {...props}
      form={formik}
      title="Edit product"
      submitLabel="Save"
      buttonVariant="contained"
      maxWidth="md"
    >
      TODO
    </FormDialog>
  );
}

export function EditPricingAndPortionsDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const formik = useFormik({
    initialValues: {},
    onSubmit(values, formikHelpers) {},
  });

  return (
    <FormDialog
      {...props}
      form={formik}
      title="Edit pricing and portions"
      submitLabel="Save"
      buttonVariant="contained"
      maxWidth="md"
    >
      TODO
    </FormDialog>
  );
}
