import { mf } from "../../utils/formatters";
import { ConfirmDialog } from "../confirm-dialog";

const content = {
  title: mf(
    `Sync {count, plural, =1 {1 edited product} other {# edited products}} to location's devices?`,
  ),
  description: `This process might take a couple of minutes.`,
  submitLabel: `Sync`,
};

export function SyncProductsDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  productsOutOfSync: number;
}) {
  const { onSuccess, ...dialogProps } = props;

  return (
    <ConfirmDialog
      {...dialogProps}
      title={content.title.format({ count: props.productsOutOfSync })}
      description={content.description}
      submitLabel={content.submitLabel}
      onSubmit={async () => {
        // TODO
        // await unpackResponse(client);
        onSuccess();
      }}
    />
  );
}
