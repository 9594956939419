import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { formatCurrency } from "../../utils/formatters";
import { DialogButton } from "../dialog-button";
import {
  AddProductDialog,
  EditPricingAndPortionsDialog,
  EditProductDialog,
} from "../dialogs/product-dialogs";
import { LocationFetchErrorCard } from "../info-card";
import LoadingSpinner from "../loading-spinner";
import { MoreMenu, MoreMenuDialogItem } from "../more-menu";
import { OutOfSyncAlert } from "../out-of-sync-alert";
import { Pageable, usePageable } from "../use-pageable";
import { ViewHeader } from "../view-header";
import { ViewTablePagination } from "../view-table-pagination";

function ProductCard(
  props: Schema["Product"] & { revalidateView: () => void },
) {
  return (
    <Card
      sx={{
        display: "grid",
        gridTemplateColumns: "128px 1fr",
        gap: 6,
        padding: 4,
      }}
    >
      <Box
        sx={{
          borderRadius: 2,
          aspectRatio: 1,
          backgroundColor: "#f7f7f7",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No picture
      </Box>

      <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr auto" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gap: 3,
            alignItems: "center",
            marginBlockEnd: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h6" component="h3" fontWeight="bold">
              {props.name}
            </Typography>

            {!props.isSynced && (
              <Chip label="Not synced" color="warning" size="small" />
            )}
          </Box>
          <MoreMenu>
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return (
                  <EditProductDialog
                    {...dialogProps}
                    onSuccess={props.revalidateView}
                  />
                );
              }}
            >
              Edit product
            </MoreMenuDialogItem>
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return (
                  <EditPricingAndPortionsDialog
                    {...dialogProps}
                    onSuccess={props.revalidateView}
                  />
                );
              }}
            >
              Edit portions and pricing
            </MoreMenuDialogItem>
          </MoreMenu>
        </Box>

        <Box
          sx={{
            paddingBlock: 2,
            paddingInline: 4,
            backgroundColor: "#F7F7F7",
            borderRadius: 2,
            fontSize: 14,
            lineHeight: 1.5,
            color: "black",
            display: "grid",
            gap: 1,
          }}
        >
          <div>
            <strong>Unit volume price</strong>{" "}
            {formatCurrency("USD", props.pricePerUnit)} per ounce
          </div>

          {props.portions?.map((productPortion) => {
            return (
              <div key={productPortion.portion.id}>
                <strong>
                  {productPortion.portion.name}
                  {" - "}
                  {productPortion.portion.quantity}oz
                </strong>{" "}
                {formatCurrency("USD", productPortion.portionPrice)}
              </div>
            );
          })}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 4,
            fontSize: 14,
            marginBlockStart: 4,
          }}
        >
          {props.isActive ? (
            <Chip label="Active" size="small" color="primary" />
          ) : (
            <Chip label="Not active" size="small" />
          )}
          <div>
            Type: <strong>{props.productType.label}</strong>
          </div>
          <div>
            Style: <strong>{props.style}</strong>
          </div>
          <div>
            PLU: <strong>{props.plu}</strong>
          </div>
        </Box>
      </Box>
    </Card>
  );
}

function LocationProductsView(props: {
  products: Schema["ProductPage"];
  pageable: Pageable;
  location: Schema["LocationBase"];
  productsOutOfSync: number;
  revalidateView: () => void;
  productTypes: Schema["ProductType"][];
}) {
  const { productsOutOfSync, location, productTypes } = props;

  return (
    <>
      <ViewHeader
        title={`${location.name} Products`}
        button={
          <DialogButton
            variant="contained"
            renderDialog={(dialogProps) => {
              return (
                <AddProductDialog
                  {...dialogProps}
                  locationId={location.id}
                  onSuccess={props.revalidateView}
                  productTypes={productTypes}
                />
              );
            }}
          >
            Add product
          </DialogButton>
        }
        breadcrumb={[
          {
            label: "Locations",
            url: `/location/list`,
          },
          {
            label: location.name,
            url: `/location/${location.id}/profile`,
          },
          {
            label: "Products",
          },
        ]}
      />

      {productsOutOfSync > 0 && (
        <Stack sx={{ marginBlockEnd: 6 }}>
          <OutOfSyncAlert {...props} />
        </Stack>
      )}

      <Typography variant="h5" component="h2" sx={{ marginBottom: 6 }}>
        All products
      </Typography>

      {props.products.content.length === 0 ? (
        <Box>
          <Typography variant="body2">No products added yet.</Typography>
        </Box>
      ) : (
        <Stack gap={4}>
          {props.products.content.map((product) => {
            return (
              <ProductCard
                key={product.id}
                {...product}
                revalidateView={props.revalidateView}
              />
            );
          })}
          <ViewTablePagination
            {...props.pageable.paginationProps}
            totalElements={props.products.totalElements}
            labelRowsPerPage="Items per page"
          />
        </Stack>
      )}
    </>
  );
}

export function LocationProductsFetch() {
  const { locationId } = useParams<"locationId">();
  const params = { path: { locationId: Number(locationId) } };
  const pageable = usePageable({ initialSortProp: "name" });

  const productTypesResponse = useClientSWR("/products/types", {
    // We need all product types as there is no support for pagination where
    // we want to use these values.
    params: { query: { page_size: 1000 } },
  });
  const locationResponse = useClientSWR("/locations/{locationId}", { params });
  const locationProductsResponse = useClientSWR(
    "/locations/{locationId}/products",
    { params: { ...params, query: pageable.query } },
  );

  const locationSyncStatusResponse = useClientSWR(
    "/locations/{locationId}/synchronization-status",
    { params },
  );

  const error =
    locationProductsResponse.error ||
    locationResponse.error ||
    locationSyncStatusResponse.error ||
    productTypesResponse.error;

  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  if (
    locationProductsResponse.data &&
    locationResponse.data &&
    locationSyncStatusResponse.data &&
    productTypesResponse.data
  ) {
    return (
      <LocationProductsView
        products={locationProductsResponse.data}
        pageable={pageable}
        location={locationResponse.data}
        productsOutOfSync={
          locationSyncStatusResponse.data.productsOutOfSync ?? 0
        }
        productTypes={productTypesResponse.data.content}
        revalidateView={() => locationProductsResponse.mutate()}
      />
    );
  }

  return <LoadingSpinner />;
}
